import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import ApiClient from './client/ApiClient.js';
import Menu from './menu/Menu.js';
import Article from './article/Article.js';
import LoginForm from './form/LoginForm.js';
import AccessibilityForm from './form/AccessibilityForm.js';
import './App.scss';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            loginFormErrors: '',
            routes: [],
            accessibilityClass: ''
        };
        this.apiClient = new ApiClient(document.getElementById('root').getAttribute('data-api-url'), document.getElementById('root').getAttribute('data-api-resource-owner'));
        this.updateRoutes = this.updateRoutes.bind(this);

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.updateAccessibility = this.updateAccessibility.bind(this);
    }

    componentDidMount() {
        this.apiClient.callApi('getSession').then(
            response => {
                if (response.data.authenticated === true && response.data.id === parseInt(document.getElementById('root').getAttribute('data-api-resource-owner'))) {
                    this.setState({loggedIn: true});
                } else {
                    this.setState({loggedIn: false});
                }
                this.updateCsrfToken();
            }
        ).catch(
            error => {
                console.log(error)
            }
        );
    }

    updateAccessibility(classUpdate) {
        this.setState({accessibilityClass: classUpdate});
    }

    updateRoutes(updatedRoutes) {
        this.setState({routes: updatedRoutes});
    }

    updateCsrfToken() {
        this.apiClient.callApi('getCsrfToken').then(
            response => {
                let csrfToken = response.headers['x-csrftoken'];
                if (csrfToken === undefined) {
                    csrfToken = '';
                }
                this.setState({csrfToken: csrfToken});
                this.apiClient.updateCsrfToken(csrfToken);
            }
        ).catch(
            error => {
                console.log(error)
            }
        );
    }

    handleLogin(values) {
        this.apiClient.callApi('loginUser', values).then(
            response => {
                if (response.status === 200) {
                    this.backToRoot();
                }
            }
        ).catch(
            error => {
                console.log(error);
                this.setState({loggedIn: false, loginFormErrors: 'Bad credentials'})
            }
        );
    }

    handleLogout() {
        this.apiClient.callApi('logoutUser').then(
            response => {
                this.backToRoot();
            }
        ).catch(
            error => {
                console.log(error);
                this.backToRoot();
            }
        );
    }

    backToRoot() {
        window.location.replace('/');
    }

    render() {
        const currentRoute = window.location.pathname;
        let redirect = null;
        let loginForm = null;
        let logoutButton = null;
        const accessibilityButtons = <AccessibilityForm accessibilityClass={this.state.accessibilityClass} updateAccessibility={this.updateAccessibility} />;

        if ((!this.state.routes.includes(currentRoute) && currentRoute !== '/login') || (currentRoute === '/login' && this.state.loggedIn)) {
            if (this.state.routes.length) {
                redirect = <Redirect exact from={currentRoute} to={this.state.routes[0]} />;
            }
        } else if (currentRoute === '/login' && !this.state.loggedIn) {
            loginForm = <LoginForm handleSubmit={this.handleLogin} error={this.state.loginFormErrors} handleCancel={this.backToRoot} />;
        }
        if (this.state.loggedIn) {
            logoutButton = <Button className='w-100 logout-extra-space my-4' variant='danger' onClick={this.handleLogout}>Logout</Button>;
        }

        const routes = this.state.routes.map(url => {
            return (
                <Route path={url} key={url}>
                    <Article
                        loggedIn={this.state.loggedIn}
                        apiClient={this.apiClient}
                        currentUrl={url}
                    />
                </Route>
            );
        });

        return (
            <BrowserRouter>
                <Container fluid>
                    <Row className={this.state.accessibilityClass}>
                        <Col md={5} lg={4} xl={3} className='left-sidebar-block'>
                            {redirect}
                            {loginForm}
                            {accessibilityButtons}
                            <Menu
                                loggedIn={this.state.loggedIn}
                                apiClient={this.apiClient}
                                updateRoutes={this.updateRoutes}
                            />
                            {logoutButton}
                        </Col>
                        <Col md={7} lg={8} xl={9} className='right-content-block'>
                            <Switch>
                                {routes}
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </BrowserRouter>
        );
    }
}

export default App;

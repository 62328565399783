import React from 'react';
import Button from 'react-bootstrap/Button';
import ArticleElements from './ArticleElements.js';
import ArticleTextForm from '../form/ArticleTextForm.js';
import ArticleLinkForm from '../form/ArticleLinkForm.js';
import ArticleImageForm from '../form/ArticleImageForm.js';
import ArticleFileForm from '../form/ArticleFileForm.js';
import ArticleImageViewForm from '../form/ArticleImageViewForm.js';
import Confirmation from '../form/Confirmation.js';
import Info from '../form/Info.js';

/*
This component calls api for article elements and expects api to return data in the following format (data key of the response):
[
{"id":2,"slug":"/somelink","kind":"text","content":"some text with <b>allowed</b> html tags","position":1},
{"id":2,"slug":"/somelink","kind":"link","content":{\"title\":\"some external link\",\"url\":\"https://www.google.com\"},"position":2},
{"id":3,"slug":"/somelink","kind":"image","content":{\"id\":1},"position":3},
{"id":4,"slug":"/somelink","kind":"file","content":{\"id\":2},"position":4}
]
id - needed for edits and identification
slug - allows to determine when element should be called for
kind - recognized are text, link, image and file
content - depending on kind it will need id (for media), text (may include some html tags), title, url or some optional as after, before or position (for link)
position - used to determine order of elements
*/
class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...{articleElements: []},
            ...this.getCleanArticleChangesState()
        };
        this.closeArticleChanges = this.closeArticleChanges.bind(this);
        this.closeInfo = this.closeInfo.bind(this);
        this.sanitizeText = this.sanitizeText.bind(this);

        this.openArticleElementForm = this.openArticleElementForm.bind(this);
        this.openArticleImageForm = this.openArticleImageForm.bind(this);
        this.openArticleFileForm = this.openArticleFileForm.bind(this);
        this.submitArticleElementOrMedia = this.submitArticleElementOrMedia.bind(this);

        this.openArticleMoveForm = this.openArticleMoveForm.bind(this);
        this.handleArticleDragging = this.handleArticleDragging.bind(this);
        this.submitArticleMove = this.submitArticleMove.bind(this);

        this.openArticleDeleteForm = this.openArticleDeleteForm.bind(this);
        this.submitArticleDelete = this.submitArticleDelete.bind(this);

        this.openArticleImageViewForm = this.openArticleImageViewForm.bind(this);
    };

    componentDidMount() {
        this.refreshArticle();
    }

    getCleanArticleChangesState() {
        return {
            articleElementForm: {kind: '', element: null},
            articleImageForm: {elements: [], page: 1},
            articleFileForm: {elements: [], page: 1},
            articleMoveForm: {element: null},
            articleDeleteForm: {element: null},
            articleImageViewForm: {element: null},
            info: {text: ''}
        };
    }

    closeArticleChanges() {
        this.setState(this.getCleanArticleChangesState());
    }

    closeInfo() {
        this.setState({info: {text: ''}});
    }

    refreshArticle() {
        this.props.apiClient.callApi('getArticle', {slug: this.props.currentUrl}).then(
            response => {
                if (response.data.results) {
                    let media_ids = [];
                    const articleElements = response.data.results;
                    articleElements.forEach((element, index) => {
                        if (element.kind !== 'text') {
                            try {
                                const content = JSON.parse(element.content);
                                if (element.kind === 'image' || element.kind === 'file') {
                                    media_ids.push(content.id);
                                }
                                articleElements[index].content = content;
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    });
                    media_ids = [...new Set(media_ids)];
                    if (media_ids.length) {
                        this.props.apiClient.callApi('getMedia', {ids: media_ids}).then(
                            response => {
                                if (response.data.results) {
                                    let mediaMap = new Map();
                                    response.data.results.forEach(element => {
                                        mediaMap.set(element.id, element);
                                    });
                                    articleElements.forEach((element, index) => {
                                        if (element.kind === 'image' || element.kind === 'file') {
                                            const mediaData = mediaMap.get(element.content.id);
                                            if (mediaData === undefined) {
                                                articleElements[index].content = {};
                                            } else {
                                                articleElements[index].content = {...mediaData};
                                            }
                                        }
                                    });
                                    this.setState({...{articleElements: articleElements}, ...this.getCleanArticleChangesState()});
                                }
                            }
                        ).catch(
                            error => {
                                console.log(error)
                                this.setState({...{articleElements: articleElements}, ...this.getCleanArticleChangesState()});
                            }
                        );
                    } else {
                        this.setState({...{articleElements: articleElements}, ...this.getCleanArticleChangesState()});
                    }
                }
            }
        ).catch(
            error => {
                console.log(error)
            }
        );
    }

    getTextMappings() {
        const allowed = [
            'div', 'p', 'br', 'span',
            'h1', 'b', 'small',
            'sub', 'sup',
            'ul', 'ol', 'li'
        ];
        let all = {from: [], to: []};
        allowed.forEach(tag => {
            if ('br' === tag) {
                all.from.push('<br/>');
                all.to.push('&lt;br/&gt;');
            }
            all.from.push('<' + tag + '>');
            all.to.push('&lt;' + tag + '&gt;');
            all.from.push('</' + tag + '>');
            all.to.push('&lt;/' + tag + '&gt;');
        });

        return all;
    }

    sanitizeText(htmlString) {
        const mappings = this.getTextMappings();
        let updatedString = htmlString;
        mappings.from.forEach((value, i) => {
            updatedString = updatedString.replace((new RegExp(value, 'g')), mappings.to[i]);
        });
        updatedString = updatedString.replace(/(<([^>]+)>)/ig, '');
        mappings.to.forEach((value, i) => {
            updatedString = updatedString.replace((new RegExp(value, 'g')), mappings.from[i]);
        });

        return updatedString;
    }

    findArticleElementById(id) {
        let selectedElement = null;
        this.state.articleElements.forEach(element => {
            if (element.id === id) {
                selectedElement = element;
            }
        });

        return selectedElement;
    }

    openArticleElementForm(kind, id) {
        this.setState({articleElementForm: {kind: kind, element: this.findArticleElementById(id)}});
    }

    openArticleImageForm(page) {
        this.props.apiClient.callApi('getImages', {page: page}).then(
            response => {
                if (response.data.results.length) {
                    this.setState({articleImageForm: {elements: response.data.results, page: page}});
                } else {
                    this.setState({info: {text: 'No more images in storage'}});
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    openArticleFileForm(page) {
        this.props.apiClient.callApi('getFiles', {page: page}).then(
            response => {
                if (response.data.results.length) {
                    this.setState({articleFileForm: {elements: response.data.results, page: page}});
                } else {
                    this.setState({info: {text: 'No more files in storage'}});
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    submitArticleElementOrMedia(values) {
        let params = {
            slug: this.props.currentUrl
        }
        let kind = '';
        let method = '';
        if (this.state.articleElementForm.element === null) {
            kind = values.kind;
            method = 'addArticleElement';
            params.kind = values.kind;
            let max_position = 0;
            if (this.state.articleElements.length > 0) {
                this.state.articleElements.forEach(element => {
                    if (element.position > max_position) {
                        max_position = element.position;
                    }
                });
            }
            params.position = max_position + 1;
        } else {
            kind = this.state.articleElementForm.element.kind;
            method = 'editArticleElement';
            params.id = this.state.articleElementForm.element.id;
        }
        if ((kind === 'image' || kind === 'file')) {
            method = 'addArticleMedia';
            let position = params.position;
            let slug = params.slug;
            params = [];
            values.ids.forEach(id => {
                if (parseInt(id) > 0) {
                    let row = {slug: slug, kind: kind, content: {}, position: position};
                    row.content.id = parseInt(id);
                    row.content = JSON.stringify(row.content);
                    params.push(row);
                    position += 1;
                }
            });
        } else if (kind === 'link' && (values.url.startsWith('http://') || values.url.startsWith('https://')) && values.title.length > 3) {
            params.content = {};
            params.content.before = values.before;
            params.content.title = values.title;
            params.content.url = values.url;
            params.content.after = values.after;
            params.content.position = values.position;
            params.content = JSON.stringify(params.content);
        } else if (kind === 'text' && values.content.length > 0) {
            params.content = values.content;
        }
        if (Array.isArray(params)) {
            params.forEach((element, index) => {
                if (element.content === undefined) {
                    params[index].content = null;
                }
            });
        }
        if (params.content === undefined) {
            params.content = null;
        }
        this.props.apiClient.callApi(method, params).then(
            response => {
                this.refreshArticle();
            }
        ).catch(
            error => {
                console.log(error);
                this.setState({info: {text: 'Invalid values provided'}});
            }
        );
    }

    openArticleMoveForm(id) {
        this.setState({articleMoveForm: {element: this.findArticleElementById(id)}});
    }

    handleArticleDragging(id) {
        const hoveredElement = this.findArticleElementById(id);
        const movingElement = this.state.articleMoveForm.element;
        if (hoveredElement.id === movingElement.id) {
            return this.state.articleElements;
        }
        let updatedArticleElements = [];
        let updatedMovingElement = null;
        this.state.articleElements.forEach(element => {
            let currentElement = {
                ...element, ...{position: this.getPositionForElement(element.position, movingElement, hoveredElement)}
            };
            updatedArticleElements.push(currentElement);
            if (element.id === movingElement.id) {
                updatedMovingElement = {...currentElement}
            }
        });
        updatedArticleElements.sort((a,b) => (a.position > b.position) ? 1 : -1);
        this.setState({articleElements: updatedArticleElements, articleMoveForm: {element: updatedMovingElement}});

        return updatedArticleElements;
    }

    getPositionForElement(currentPosition, movingElement, hoveredElement) {
        if (movingElement.position > hoveredElement.position) {
            if (currentPosition < hoveredElement.position || currentPosition > movingElement.position) {
                return currentPosition;
            } else if (currentPosition >= hoveredElement.position && currentPosition < movingElement.position) {
                return currentPosition + 1;
            } else if (currentPosition === movingElement.position) {
                return currentPosition - (movingElement.position - hoveredElement.position);
            }
        } else if (movingElement.position < hoveredElement.position) {
            if (currentPosition < movingElement.position || currentPosition > hoveredElement.position) {
                return currentPosition;
            } else if (currentPosition === movingElement.position) {
                return currentPosition + (hoveredElement.position - movingElement.position);
            } else if (currentPosition > movingElement.position && currentPosition <= hoveredElement.position) {
                return currentPosition - 1;
            }
        }
    }

    submitArticleMove(id) {
        const updatedArticleElements = this.handleArticleDragging(id);
        let params = [];
        updatedArticleElements.forEach(element => {
            params.push({id: element.id, position: element.position});
        });
        this.props.apiClient.callApi('changeArticleOrder', params).then(
            response => {
                this.refreshArticle();
            }
        ).catch(
            error => {
                console.log(error);
                this.refreshArticle();
            }
        );
    }

    openArticleDeleteForm(id) {
        this.setState({articleDeleteForm: {element: this.findArticleElementById(id)}});
    }

    submitArticleDelete() {
        if (this.state.articleDeleteForm.element === null) {
            this.closeArticleChanges();
        }
        this.props.apiClient.callApi('deleteArticleElement', {id: this.state.articleDeleteForm.element.id}).then(
            response => {
                this.refreshArticle();
            }
        ).catch(
            error => {
                console.log(error);
                this.refreshArticle();
            }
        );
    }

    openArticleImageViewForm(id, direction) {
        const currentImageElement = this.findArticleElementById(id);
        if (currentImageElement === null || currentImageElement.kind !== 'image') {
            this.closeArticleChanges();

            return;
        }
        if (direction === 0) {
            this.setState({articleImageViewForm: {element: currentImageElement}});

            return;
        }
        let imageElements = [];
        this.state.articleElements.forEach(element => {
            if (element.kind === 'image') {
                imageElements.push(element);
            }
        });
        const length = imageElements.length
        let index = length;
        while (index--) {
            if (imageElements[index].id === currentImageElement.id) {
                if (direction > 0 && index < length - 1) {
                    this.setState({articleImageViewForm: {element: imageElements[index + 1]}});
                } else if (direction > 0) {
                    this.setState({articleImageViewForm: {element: imageElements[0]}});
                } else if (direction < 0 && index === 0) {
                    this.setState({articleImageViewForm: {element: imageElements[length - 1]}});
                } else if (direction < 0) {
                    this.setState({articleImageViewForm: {element: imageElements[index - 1]}})
                }
                break;
            }
        }
    }

    render() {
        let buttons = [];
        let form = null;
        let info = this.state.info.text !== ''? <Info description={this.state.info.text} handleOk={this.closeInfo} /> : null;
        if (this.props.loggedIn) {
            buttons.push(<div key='add-text' className='w-25 p-1 d-inline'><Button className='my-1' variant='success' onClick={e => this.openArticleElementForm('text')}>Add article text</Button></div>);
            buttons.push(<div key='add-link' className='w-25 p-1 d-inline'><Button className='my-1' variant='success' onClick={e => this.openArticleElementForm('link')}>Add article link</Button></div>);
            buttons.push(<div key='add-image' className='w-25 p-1 d-inline'><Button className='my-1' variant='success' onClick={e => this.openArticleImageForm(1)}>Add article images</Button></div>);
            buttons.push(<div key='add-file' className='w-25 p-1 d-inline'><Button className='my-1' variant='success' onClick={e => this.openArticleFileForm(1)}>Add article files</Button></div>);
            if (this.state.articleElementForm.kind === 'text') {
                form = (
                    <ArticleTextForm
                        handleSubmit={this.submitArticleElementOrMedia}
                        handleCancel={this.closeArticleChanges}
                        articleElement={this.state.articleElementForm.element}
                        sanitizeText={this.sanitizeText}
                    />
                );
            } else if (this.state.articleElementForm.kind === 'link') {
                form = (
                    <ArticleLinkForm
                        handleSubmit={this.submitArticleElementOrMedia}
                        handleCancel={this.closeArticleChanges}
                        articleElement={this.state.articleElementForm.element}
                    />
                );
            } else if (this.state.articleImageForm.elements.length) {
                form = (
                    <ArticleImageForm
                        handleSubmit={this.submitArticleElementOrMedia}
                        handleCancel={this.closeArticleChanges}
                        handlePagination={this.openArticleImageForm}
                        currentPage={this.state.articleImageForm.page}
                        elements={this.state.articleImageForm.elements}
                    />
                );
            } else if (this.state.articleFileForm.elements.length) {
                form = (
                    <ArticleFileForm
                        handleSubmit={this.submitArticleElementOrMedia}
                        handleCancel={this.closeArticleChanges}
                        handlePagination={this.openArticleFileForm}
                        currentPage={this.state.articleFileForm.page}
                        elements={this.state.articleFileForm.elements}
                    />
                );
            } else if (this.state.articleDeleteForm.element !== null) {
                form = (
                    <Confirmation
                        handleYes={this.submitArticleDelete}
                        handleNo={this.closeArticleChanges}
                        text='Are you sure you want to delete this article element?'
                    />
                );
            }
        }

        if (this.state.articleImageViewForm.element !== null) {
            form = (
                <ArticleImageViewForm
                    element={this.state.articleImageViewForm.element}
                    handleCancel={this.closeArticleChanges}
                    changeImage={this.openArticleImageViewForm}
                />
            );
        }

        return (
            <>
                <div className='text-center' tabIndex='0' id='article-contents'>
                    <ArticleElements
                        elements={this.state.articleElements}
                        sanitizeText={this.sanitizeText}
                        elementEdit={this.openArticleElementForm}
                        elementMove={this.openArticleMoveForm}
                        movingElement={this.state.articleMoveForm.element}
                        handleDragging={this.handleArticleDragging}
                        submitMove={this.submitArticleMove}
                        elementDelete={this.openArticleDeleteForm}
                        loggedIn={this.props.loggedIn}
                        imageView={this.openArticleImageViewForm}
                    />
                </div>
                <div className='w-100 text-center'>
                    {buttons}
                    {form}
                    {info}
                </div>
            </>
        )
    }
}

export default Article;

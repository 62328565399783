import React from 'react';
import Image from 'react-bootstrap/Image';
import FacebookIcon from '../images/facebook.png';
import FacebookBigIcon from '../images/facebookbig.png';
import InstagramIcon from '../images/instagram.png';
import InstagramBigIcon from '../images/instagrambig.png';
import YoutubeIcon from '../images/youtube.png';
import YoutubeBigIcon from '../images/youtubebig.png';
import './ArticleLink.scss';
import MovableBlock from '../form/MovableBlock.js';
import TooltipButtons from '../form/TooltipButtons.js';

class ArticleLink extends React.Component {
    render() {
        let linkContent = null;
        switch (this.props.content.title) {
            case 'facebooklogo':
                linkContent = <Image className='social-media-icon-logo' src={FacebookIcon} alt={this.props.content.title} />;
                break;
            case 'facebooklogobig':
                linkContent = <Image className='social-media-icon-logo-big' src={FacebookBigIcon} alt={this.props.content.title} />;
                break;
            case 'instagramlogo':
                linkContent = <Image className='social-media-icon-logo' src={InstagramIcon} alt={this.props.content.title} />;
                break;
            case 'instagramlogobig':
                linkContent = <Image className='social-media-icon-logo-big' src={InstagramBigIcon} alt={this.props.content.title} />;
                break;
            case 'youtubelogo':
                linkContent = <Image className='social-media-icon-logo' src={YoutubeIcon} alt={this.props.content.title} />;
                break;
            case 'youtubelogobig':
                linkContent = <Image className='social-media-icon-logo-big' src={YoutubeBigIcon} alt={this.props.content.title} />;
                break;
            case 'youtubefilm':
                let video_id = '';
                linkContent = 'Invalid video url';
                if (this.props.content.url.indexOf('https://www.youtube.com/watch?v=') !== -1) {
                    video_id = this.props.content.url.substring(this.props.content.url.indexOf('watch?v=') + 8);
                } else if (this.props.content.url.indexOf('https://youtu.be/') !== -1) {
                    video_id = this.props.content.url.substring(this.props.content.url.indexOf('youtu.be/') + 9);
                } else {
                    break;
                }
                if (video_id.indexOf('?') !== -1) {
                    video_id = video_id.substring(0, video_id.indexOf('?'));
                } else if (video_id.indexOf('&') !== -1) {
                    video_id = video_id.substring(0, video_id.indexOf('&'));
                }
                if (video_id.length) {
                    linkContent = (
                        <div className='social-media-youtube-wrapper'>
                            <Image className='social-media-youtube-film' src={'https://i1.ytimg.com/vi/' + video_id + '/hqdefault.jpg'} alt={this.props.content.title} />
                            <Image className='social-media-youtube-play' src={YoutubeBigIcon} alt={this.props.content.title} />;
                        </div>
                    );
                }
                break;
            default:
                linkContent = this.props.content.title;
        }
        const linkBefore = this.props.content.before !== undefined ? this.props.content.before + ' ' : null;
        const linkAfter = this.props.content.after !== undefined ? ' ' + this.props.content.after : null;
        const className = this.props.content.position !== undefined && this.props.content.position === 'center' ? 'article-block link-text-center' : 'article-block link-text-left';

        const link = this.props.content.title === 'youtubefilm' ? (
            <div className={className}>
                <div>{linkBefore}</div>
                <a className='text-secondary' target='_blank' rel='noreferrer' href={this.props.content.url}>{linkContent}</a>
                <div>{linkAfter}</div>
            </div>
        ) : (
            <div className={className}>
                <span>{linkBefore}</span>
                <a className='text-secondary' target='_blank' rel='noreferrer' href={this.props.content.url}>{linkContent}</a>
                <span>{linkAfter}</span>
            </div>
        );

        if (!this.props.loggedIn) {
            return link;
        }

        if (this.props.movingElement !== null) {
            return (
                <MovableBlock
                    movingElement={this.props.movingElement}
                    handleDragging={this.props.handleDragging}
                    submitMove={this.props.submitMove}
                    elementId={this.props.elementId}
                    content={link}
                />
            );
        } else {
            return (
                <TooltipButtons
                    elementEdit={this.props.elementEdit}
                    elementMove={this.props.elementMove}
                    elementDelete={this.props.elementDelete}
                    elementId={this.props.elementId}
                    elementEditKind='link'
                    content={link}
                />
            );
        }
    }
}

export default ArticleLink;
